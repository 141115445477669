import React, { useEffect, useRef, useState } from "react";

import {
    Image,
    Modal,
    Platform,
    Text,
    TouchableOpacity,
    View,
    ViewStyle
} from "react-native";
import Images, { bundleImage } from "../../../specific/utils/Images";
import Divider from "../../designSystem/Divider/Divider";
import User from "../../data/user/User";
import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import Button from "../../designSystem/Button";
import { AppTarget, getAppTarget } from "../../../specific/utils/Navigation/Host";
import { getDocumentUrlForMediaId } from "../../../specific/services/AppWrite/AppWriteDatabase";
import { PDFDocument } from "./SuperRoom";
import { openUrl } from "../../utils/WebLink";

import InViewModal from "../../../specific/components/InViewModal/InViewModal";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { addDataToUser } from "../../services/Database";
import SVGView from "../../utils/SvgView";
import { default as IcCheck } from "../../../shared/assets/svg/icons/solid/check.svg";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { LaunchStep } from "../../models/Models";
import { getLocalLaunchSteps } from "../../../specific/utils/LocalStorage";
import i18n, { getLanguage } from "../../utils/Localization/Localization";
import PDFViewer from "../../../specific/components/PDF/PDFViewer";
import * as Constants from "../../utils/Constants";
import ActivityFilterByLevel from "../Filters/ActivityFilterByLevel";

interface StartingStepsProps {
    width: number,
    height: number,
    componentId?: string,
    navigate?: string,
    showAtLaunch?: boolean,
    deeplink?: string,
    designElements: any
}

type StartingStep = {
    id: string,
    icon: number | bundleImage,
    title: string
}

const StartingSteps = (props: StartingStepsProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();
    const inViewModal = useRef<InViewModal>(null);

    const { componentId, navigate, width, height, showAtLaunch = false, deeplink = "", designElements } = props;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [showSteps, setShowSteps] = useState<boolean>(showAtLaunch);
    const [launchSteps, setLaunchSteps] = useState<LaunchStep[] | undefined>(undefined);
    const [pdfDocumentToShow, setPdfDocumentToShow] = useState<PDFDocument | undefined>(undefined);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [resfreshView, setRefreshView] = useState<number>(0);

    let backgroundColor: string = appTheme.parentBackgroundColor;
    let stepsColor = "#354F84";
    let macaroonImage = images.icParentMacaroon;
    let itemsColor: string = appTheme.homeColor;
    let itemsTextColor: string = appTheme.darkBlue;
    if (user.profile === "teacher") {
        stepsColor = "#716899";
        backgroundColor = appTheme.teacherBackgroundColor;
        macaroonImage = images.icTeacherMacaroon;
        itemsColor = appTheme.schoolColor;
        itemsTextColor = appTheme.white;
    } else if (user.profile === "animator") {
        stepsColor = appTheme.schoolColor;
        backgroundColor = appTheme.animatorBackgroundColor;
        macaroonImage = images.icTeacherMacaroon;
        itemsColor = appTheme.schoolColor;
        itemsTextColor = appTheme.white;
    }

    const sectionButtonStyle: ViewStyle = {
        marginTop: appTheme.pixelPerfect(10),
        paddingHorizontal: appTheme.pixelPerfect(20),
        paddingVertical: appTheme.pixelPerfect(5),
        backgroundColor: itemsColor,
        borderRadius: appTheme.pixelPerfect(30)
    };

    useEffect(() => {
        if (launchSteps !== undefined) {
            setViewDidAppear(true);
        }
    }, [launchSteps]);

    const onViewWillAppear = async () => {
        const localLaunchSteps = await getLocalLaunchSteps();
        const localLaunchStepsData = localLaunchSteps.data;
        let updatedLaunchStepsByTarget: LaunchStep[] = [];
        for (const aLocalLaunchStepKey in localLaunchStepsData) {
            if (Object.prototype.hasOwnProperty.call(localLaunchStepsData, aLocalLaunchStepKey)) {
                let aLaunchStep: LaunchStep = localLaunchStepsData[aLocalLaunchStepKey];
                console.log({ aLaunchStep, profile: user.profile })
                if (aLaunchStep.target.indexOf(user.profile) !== -1) {
                    // On va regarder où le ranger en fonction de l'ordre d'affichage défini en base
                    let launchStepInserted: boolean = false;
                    if (aLaunchStep.order_by_target[user.profile] !== undefined) {
                        for (const anOrderedIndex in updatedLaunchStepsByTarget) {
                            if (Object.prototype.hasOwnProperty.call(updatedLaunchStepsByTarget, anOrderedIndex)) {
                                const anOrderedLaunchStep: LaunchStep = updatedLaunchStepsByTarget[anOrderedIndex];
                                if (anOrderedLaunchStep.order_by_target[user.profile] !== undefined) {
                                    if (aLaunchStep.order_by_target[user.profile] < anOrderedLaunchStep.order_by_target[user.profile]) {
                                        updatedLaunchStepsByTarget.splice(parseInt(anOrderedIndex), 0, aLaunchStep);
                                        launchStepInserted = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (launchStepInserted === false) {
                        updatedLaunchStepsByTarget.push(aLaunchStep);
                    }
                }
            }
        }
        setLaunchSteps(updatedLaunchStepsByTarget);
    }

    const onCellRequest = async (request: LaunchStep, backgroundColor?: string) => {
        const appTarget: AppTarget = getAppTarget();
        let color: string = appTheme.schoolColor;
        if (user.profile === "family") {
            color = appTheme.homeColor;
        }
        if (backgroundColor === undefined) {
            backgroundColor = color;
        }
        if (request.action.type === "pdf") {
            let pdfUrl: string | undefined = undefined;
            let pdfTitle: string = "Lili";
            if (request.action.url !== undefined) {
                pdfUrl = request.action.url[getLanguage()];
            } else if ((request.action.urls !== undefined) && (request.action.urls[user.profile] !== undefined)) {
                pdfUrl = request.action.urls[user.profile][getLanguage()];
            }
            if ((appTarget !== "public") && (request.action.id !== undefined)) {
                pdfUrl = getDocumentUrlForMediaId({ media_id: request.action.id });
            }
            if ((request.action.title !== undefined) && (request.action.title[getLanguage()] !== undefined)) {
                pdfTitle = request.action.title[getLanguage()];
            } else if ((request.action.titles !== undefined) && (request.action.titles[user.profile] !== undefined) && (request.action.titles[user.profile][getLanguage()] !== undefined)) {
                pdfTitle = request.action.titles[user.profile][getLanguage()];
            }
            if ((pdfUrl !== undefined) && (pdfUrl.length > 0)) {
                setPdfDocumentToShow({
                    title: pdfTitle,
                    url: pdfUrl,
                    color
                });
            }
        } else if (request.action.type === "show_filters") {
            setTimeout(() => {
                setShowFilters(true);
            }, 500);
        } else if (request.action.type === "link") {
            if ((request.action.url !== undefined) && (request.action.url[getLanguage()] !== undefined)) {
                openUrl(request.action.url[getLanguage()]);
            }
        } else if (request.action.type === "activity") {
            const passProps = {
                activityKey: request.action.activity_key,
                categoryKey: request.action.category_key,
                sectionKey: request.action.section_key,
                context: user.profile,
                isModal:true
            };
            if (Platform.OS === "web") {
                inViewModal.current?.showAlert({
                    name: Constants.ScreenActivitySheet,
                    passProps
                });
            } else {
                Navigation.showModal({
                    componentId,
                    navigate,
                    passProps,
                    name: Constants.ScreenActivitySheet
                })
            }
        }
        if (user.steps !== undefined) {
            if ((user.steps[request.id] === undefined) || ((user.steps[request.id] !== undefined) && (user.steps[request.id] === false))) {
                let userSteps = user.steps;
                userSteps[request.id] = true;
                await addDataToUser({ key: "steps", value: userSteps, user_id: user.uid });
                setRefreshView(new Date().getTime());
            }
        }
    }

    const getPDFViewer = () => {
        if (pdfDocumentToShow === undefined) {
            return <View />
        }
        return <PDFViewer
            title={pdfDocumentToShow.title}
            pdfUrl={pdfDocumentToShow.url}
            color={pdfDocumentToShow.color}
            closeTheDocument={() => setPdfDocumentToShow(undefined)} />
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    const showStepAction = async (step: LaunchStep) => {
        if (step.hide_steps === true) {
            setShowSteps(false);
        }
        onCellRequest(step, backgroundColor);
    }

    const stepCell = (data: { step: LaunchStep, isFirst: boolean, isLast: boolean }) => {
        const { step, isFirst, isLast } = data;
        let isStepChecked = false;
        if (user.steps !== undefined) {
            if (user.steps[step.id] !== undefined) {
                isStepChecked = user.steps[step.id];
            }
        }
        let stepImage: any = images[step.icon];
        if (step.id === "notice") {
            stepImage = images.illStepNotice;
        }
        const playButtonSize = Platform.OS === "web" ? appTheme.pixelPerfect(42) : appTheme.pixelPerfect(34);
        const lineHeight = Platform.OS === "web" ? appTheme.pixelPerfect(10) : appTheme.pixelPerfect(5);
        const bottomLine = isLast === true ? <View /> : <View style={{ position: "absolute", width: 1, height: lineHeight, bottom: 0, left: playButtonSize / 2, backgroundColor: backgroundColor }} />;
        const topLine = isFirst === true ? <View /> : <View style={{ position: "absolute", width: 1, height: lineHeight, top: 0, left: playButtonSize / 2, backgroundColor: backgroundColor }} />;
        const actionButtonBackgroundColor = isStepChecked === false ? appTheme.white : stepsColor;
        const actionButtonTextColor = isStepChecked === true ? appTheme.white : stepsColor;
        const mediaCellWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        const checkView = isStepChecked === false ? <View style={{ width: playButtonSize }} /> : <View style={{ width: playButtonSize, justifyContent: "center", alignItems: "center" }}><SVGView Component={IcCheck} color={appTheme.white} size={14} /></View>;
        let stepTitle: string = "";
        if ((step.title !== undefined) && (step.title[getLanguage()] !== undefined)) {
            stepTitle = step.title[getLanguage()];
        } else if ((step.titles !== undefined) && (step.titles[user.profile][getLanguage()] !== undefined)) {
            stepTitle = step.titles[user.profile][getLanguage()];
        }
        return <TouchableOpacity onPress={() => showStepAction(step)} style={{ backgroundColor: appTheme.white, flexDirection: "row", height: appTheme.pixelPerfect(45) + lineHeight, justifyContent: "flex-start", alignItems: "center", width: appTheme.getFlexDirectionForSplitScreen() === "row" ? mediaCellWidth * 0.6 : mediaCellWidth * 0.9 }}>
            {topLine}
            {bottomLine}
            <View style={{ borderWidth: appTheme.pixelPerfect(0.5), borderColor: stepsColor + "4D", borderRadius: (playButtonSize + appTheme.pixelPerfect(6)) / 2, backgroundColor: actionButtonBackgroundColor, flexDirection: "row", height: playButtonSize + appTheme.pixelPerfect(6), justifyContent: "flex-start", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(2), width: appTheme.getFlexDirectionForSplitScreen() === "row" ? mediaCellWidth * 0.6 : mediaCellWidth * 0.9 }}>
                <View style={{ borderWidth: 1, borderColor: appTheme.darkBlue, width: playButtonSize, height: playButtonSize, borderRadius: playButtonSize / 2, justifyContent: "center", alignItems: "center", backgroundColor: actionButtonBackgroundColor, marginEnd: appTheme.pixelPerfect(10) }}>
                    <Image source={stepImage} style={{ width: playButtonSize, height: playButtonSize, borderRadius: playButtonSize / 2 }} />
                </View>
                <Text numberOfLines={3} style={{ color: actionButtonTextColor, flex: 1, fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(8) }}>
                    {stepTitle}
                </Text>
                {checkView}
            </View>
        </TouchableOpacity>
    }

    const getStepList = () => {
        let stepListView = [];
        for (const aStepIndex: string in launchSteps) {
            if (Object.prototype.hasOwnProperty.call(launchSteps, aStepIndex)) {
                const aStep: LaunchStep = launchSteps[parseInt(aStepIndex)];
                stepListView.push(stepCell({
                    step: aStep,
                    isFirst: parseInt(aStepIndex) === 0,
                    isLast: parseInt(aStepIndex) === launchSteps.length - 1,
                }))
            }
        }
        return stepListView
    }

    const getSteps = () => {
        if (showSteps === false) {
            return <View />
        }

        const absoluteBadgeSize: number = Platform.OS === "web" ? 80 : 56;
        const absoluteBorderSize: number = Platform.OS === "web" ? 4 : 2;
        const badgeSize: number = Platform.OS === "web" ? appTheme.pixelPerfect(absoluteBadgeSize) : appTheme.pixelPerfect(absoluteBadgeSize);
        return <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: backgroundColor + "CC" }}>
            <View >
                <Divider size={absoluteBadgeSize / 2} />
                <View style={[Style.shadowed, { backgroundColor: appTheme.white, borderTopEndRadius: appTheme.pixelPerfect(20), borderTopStartRadius: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(40), paddingBottom: appTheme.pixelPerfect(40), alignItems: "center" }]}>
                    <Text style={{ textAlign: "center", textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7) }}>
                        {i18n.t("startingSteps.modal.title", { number_of_steps: launchSteps?.length })}
                    </Text>
                    <Divider />
                    {getStepList()}
                    <Divider />
                    <Button onPress={() => setShowSteps(false)} title={i18n.t("startingSteps.modal.button")} link={true} />
                </View>
                <View style={{ width: appTheme.getFullScreenWidth(), alignItems: "center", position: "absolute" }}>
                    <View style={{ justifyContent: "center", alignItems: "center", overflow: "hidden", backgroundColor: appTheme.darkBlue, borderWidth: appTheme.pixelPerfect(absoluteBorderSize), borderColor: appTheme.white, width: badgeSize + appTheme.pixelPerfect(absoluteBorderSize), height: badgeSize + appTheme.pixelPerfect(absoluteBorderSize), borderRadius: (badgeSize + appTheme.pixelPerfect(absoluteBorderSize)) / 2 }}>
                        <Image source={images.illSevenSteps} style={{ width: badgeSize, height: badgeSize, borderRadius: badgeSize / 2 }} />
                    </View>
                </View>
            </View>
        </View>
    }

    const getStepsSection = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewWillAppear} style={{ alignItems: "center", justifyContent: "center", height: appTheme.pixelPerfect(60), width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
                <ActivityIndicator loading={true} color={appTheme.white} />
            </View>
        }
        const macaroonSize = Platform.OS === "web" ? 60 : 40;
        const owlWidth = Platform.OS === "web" ? 60 : 40;
        const owlHeight = owlWidth * 3 / 2;
        const arrowLeftPosition = Platform.OS === "web" ? appTheme.pixelPerfect(20) : appTheme.pixelPerfect(16);
        const arrowTopPosition = Platform.OS === "web" ? appTheme.pixelPerfect(38) : appTheme.pixelPerfect(32);
        const arrowHeight = Platform.OS === "web" ? appTheme.pixelPerfect(24) : appTheme.pixelPerfect(16);
        const arrowWidth = Platform.OS === "web" ? appTheme.pixelPerfect(26) : appTheme.pixelPerfect(18);
        return <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
            <View style={{ alignItems: "center", justifyContent: "flex-start", width: width + appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(20) }}>
                <View style={{ width: width, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), height: height, justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7) }}>
                        {i18n.t("startingSteps.title")}
                    </Text>
                    <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(6) }}>
                        {i18n.t("startingSteps.subtitle", { number_of_steps: launchSteps?.length })}
                    </Text>
                    <TouchableOpacity onPress={() => setShowSteps(true)} style={sectionButtonStyle}>
                        <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: itemsTextColor, fontSize: appTheme.pixelPerfectForFont(6) }}>
                            {i18n.t("startingSteps.button")}
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), left: appTheme.pixelPerfect(4), width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }}>
                    <Image source={macaroonImage} style={{ position: "absolute", width: appTheme.pixelPerfect(macaroonSize), height: appTheme.pixelPerfect(macaroonSize) }} />
                    <Text style={{ textAlign: "center", fontFamily: appTheme.primaryBoldFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(3), textTransform: "uppercase", lineHeight: appTheme.pixelPerfect(10), transform: [{ rotate: '-20deg' }] }}>
                        {i18n.t("startingSteps.macaroon")}
                    </Text>
                    <Image source={images.icArrow} style={{ width: arrowWidth, height: arrowHeight, position: "absolute", left: arrowLeftPosition, top: arrowTopPosition }} />
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), right: appTheme.pixelPerfect(4), width: appTheme.pixelPerfect(owlWidth), height: appTheme.pixelPerfect(owlHeight) }}>
                    <Image source={images.icOwlFlysuit} style={{ position: "absolute", width: appTheme.pixelPerfect(owlWidth), height: appTheme.pixelPerfect(owlHeight) }} resizeMode="contain" />
                </View>
            </View>
        </View>;
    }

    return <View style={{ flex: 1, justifyContent: "flex-end" }}>
        {getStepsSection()}
        <Modal visible={showSteps} animationType="slide" transparent={true}>
            {getSteps()}
        </Modal>
        <Modal animationType="slide" visible={pdfDocumentToShow !== undefined} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: designElements.mainBackgroundColor + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: designElements.mainBackgroundColor + "80", width: appTheme.getFullAppWidth() }}>
                    {getPDFViewer()}
                </View>
            </View>
        </Modal>
        <Modal
            transparent={true}
            animationType="slide"
            visible={showFilters}
            style={{ flex: 1, justifyContent: "flex-end" }}>
            <ActivityFilterByLevel backgroundColor={appTheme.schoolColor} toggleFilters={toggleFilters} selected={user.filter_by_level} />
        </Modal>
        <InViewModal ref={inViewModal} />
    </View>

}

export default StartingSteps;